<template>
  <Dropdown>
    <template #toggle="{ toggle }">
      <button
        class="flex items-center ml-2 text-xl"
        :class="{ 'cursor-default': !hasMultipleTenants }"
        @click="hasMultipleTenants ? toggle() : null"
      >
        <SvgIcon
          name="share"
          class="w-6 h-6 text-primary fill-transparent"
          aria-hidden="true"
        />
        <div class="ml-3 text-base text-primary">
          {{ activeTenant?.name || $t("general.switchMandate") }}
        </div>
      </button>
    </template>

    <div v-if="hasMultipleTenants" class="flex flex-col w-full">
      <button
        v-for="tenant in availableTenants"
        :key="tenant.tag"
        class="flex justify-between py-2.5 first:pt-0 hover:text-primary"
        :class="{ 'text-primary': tenant.tag == activeTenant?.tag }"
        @click="setActiveTenant(tenant)"
      >
        <span>{{ tenant.name }}</span>
        <SvgIcon
          v-if="tenant.tag == activeTenant?.tag"
          name="check"
          class="svg-icon--lg"
        />
      </button>
    </div>
  </Dropdown>
</template>

<script lang="ts" setup>
import Dropdown from "@/components/TpfDropdown.vue";
import { useAuthStore, type Tenant } from "@/store/Auth";
import { notify } from "@kyvg/vue3-notification";
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute, useRouter } from "vue-router";
import { useBaseStore } from "@/store/Base";

const authStore = useAuthStore();
const baseStore = useBaseStore();

const { t } = useI18n();
const router = useRouter();
const route = useRoute();

const availableTenants = computed(() => {
  return authStore.availableTenants;
});

const activeTenant = computed(() => {
  if (!availableTenants.value) {
    return;
  }
  return availableTenants.value.find(
    (tenant) => tenant.tag == authStore.activeTenant
  );
});

const hasMultipleTenants = computed(() => {
  if (availableTenants.value) {
    return availableTenants.value.length > 1;
  }
  return false;
});

const setActiveTenant = async (tenant: Tenant) => {
  baseStore.setIsSwitchingTenant(true);

  // If we're on a dossier, push to home. It makes no sense to stay
  // on the dossier after a tenant switch
  if (route.name == "dossier") {
    // we can safely push to home because this TenantSwitch component
    // persists even if the route is changed
    // so the subsequent code is still being executed
    router.push({ name: "dashboard" });
  }

  authStore.setActiveTenant(tenant.tag);

  if (!authStore.availableTenants) return;

  await authStore.restoreSession();
  baseStore.setIsSwitchingTenant(false);

  notify({
    type: "sucess",
    text: t("general.tenantSwitched", { tenant: tenant.name }),
  });
};
</script>
