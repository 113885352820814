<template>
  <a
    v-tooltip.bottom-start="username"
    class="link flex items-center justify-between cursor-pointer !no-underline font-sans"
    :disabled="isHere"
    @click="click"
  >
    <SvgIcon name="user-light" class="w-6 h-6" aria-hidden="true" />
    <div class="text ml-3">{{ $t("general.profile") }}</div>
  </a>
</template>

<script setup lang="ts">
import { useAuthStore } from "@/store/Auth";
import { computed } from "vue";
import { useRoute, useRouter } from "vue-router";

const route = useRoute();
const router = useRouter();
const authStore = useAuthStore();

const isHere = computed(() => route.name === "profile" && !route.params.id);

const username = computed(() => {
  if (authStore.alias) {
    const tenantName = authStore.availableTenants?.find(
      (tenant) => tenant.tag == authStore.activeTenant
    )?.name;
    if (tenantName) {
      // Remove tenant from alias.
      return authStore.alias.replace(`${tenantName} `, "");
    }
    return authStore.alias;
  }
  // return firstName + lastName as a fallback
  return `${authStore.firstName} ${authStore.lastName}`;
});

const click = () => {
  router.push("/profile");
};
</script>

<style lang="postcss" scoped>
.link:hover > .text {
  text-decoration: underline;
  text-decoration-thickness: 2px;
}
</style>
