<template>
  <label
    :class="['block', { 'cursor-not-allowed': disabled }]"
    for="tpfFreeTextField"
  >
    <div class="flex items-center justify-between">
      <span
        class="flex text-sm mb-1.5"
        :class="[
          { 'text-grayTpf-600': props.disabled },
          { block: props.isBlock },
          { 'inline-flex': !props.isBlock },
          { 'mr-4': !props.isBlock },
          { 'is-required': props.required },
          props.classLabel,
        ]"
      >
        <slot name="label" :label="props.label">
          {{ props.label }}
        </slot>
      </span>
      <span>
        <slot />
      </span>
    </div>
    <div
      ref="tpfFreeTextFieldWrapperRef"
      class="form-textarea focus:outline-2 relative h-[3.125rem]"
      :class="{
        'pr-[1.875rem]': !isTextAreaOpen,
        'h-auto': isTextAreaOpen,
        'pb-[1.5rem]': isTextAreaOpen,
        'border-primary-500 ring ring-[#134AB4]/[.2] h-[12.5rem]':
          isTextAreaFocused && !props.disabled,
        'cursor-not-allowed bg-grayTpf-50 text-grayTpf-500': props.disabled,
        'is-error': props.error,
      }"
      @click="onClick"
    >
      <textarea
        ref="tpfFreeTextFieldRef"
        v-model="localValue"
        :rows="textAreaRows"
        :maxlength="props.max"
        class="w-full bg-transparent border-none outline-none placeholder:text-grayTpf-200"
        :class="{
          'overflow-hidden h-6': !isTextAreaOpen,
          'h-36': isTextAreaOpen,
          'cursor-not-allowed': props.disabled,
          classInput,
        }"
        name="tpfFreeTextField"
        :placeholder="props.placeholder"
        :disabled="props.disabled"
        :data-testid="`${props.testid}`"
        @keypress="onKeypress"
        @focus="onClick"
        @blur="blur"
      />
      <span
        v-if="isTextAreaOpen"
        class="absolute bottom-2 right-4 text-grayTpf-300"
        :class="{ 'text-grayTpf-400': props.disabled }"
      >
        {{ props.max - charCounter }}/{{ props.max }}
      </span>
      <span
        v-else
        class="absolute right-[1.65rem] bottom-[0.75rem] text-primary"
        :class="{ 'text-grayTpf-500': props.disabled }"
      >
        ...
      </span>
    </div>
  </label>
</template>

<script setup lang="ts">
import { computed, ref, type Ref, onMounted } from "vue";

const props = withDefaults(
  defineProps<{
    modelValue: string;
    max?: number;
    required?: boolean;
    isBlock?: boolean;
    disabled?: boolean;
    classLabel?: string;
    classInput?: string;
    label: string;
    placeholder?: string;
    error?: boolean;
    classTextArea?: string;
    isExpanded?: boolean;
    testid?: string;
  }>(),
  {
    max: 500,
    required: false,
    isBlock: false,
    disabled: false,
    classLabel: "",
    classInput: "",
    placeholder: "",
    error: false,
    classTextArea: "",
    isExpanded: true,
    testid: "",
  },
);

const emit = defineEmits(["update:modelValue", "blur"]);

const tpfFreeTextFieldRef: Ref<HTMLTextAreaElement | null> = ref(null);
const tpfFreeTextFieldWrapperRef: Ref<HTMLDivElement | null> = ref(null);
const isTextAreaOpen: Ref<boolean> = ref(true);
const isTextAreaFocused: Ref<boolean> = ref(false);

const localValue = computed({
  get: (): string => {
    return props.modelValue;
  },
  set: (newValue: string) => {
    emit("update:modelValue", newValue);
  },
});

const charCounter = computed((): number => {
  return localValue.value.length;
});

const textAreaRows = computed(() => {
  return isTextAreaOpen.value ? 10 : 1;
});

onMounted(() => {
  openTextAreaIfExpanded();
});

const openTextAreaIfExpanded = () => {
  if (props.isExpanded) {
    openTextArea();
  }
};

const onClick = () => {
  if (props.disabled) return;
  if (!tpfFreeTextFieldRef.value) return;
  // When opening the text field from the outer edge,
  // the input is not selected and thus not focused.
  tpfFreeTextFieldRef.value.focus();
  isTextAreaFocused.value = true;
  openTextArea();
};

const blur = (event: any) => {
  emit("blur");
  isTextAreaFocused.value = false;

  // The button which creates the notes triggers the blur function of the text field, we need to stop it from closing the text area,
  // since this leads to a double click until the note can be sent.
  const createButtonEventTarget = event.relatedTarget
    ? event.relatedTarget.getAttribute("id")
    : null;
  if (
    createButtonEventTarget &&
    createButtonEventTarget === "createNoteButton"
  ) {
    return;
  }
  closeTextArea(tpfFreeTextFieldRef.value, tpfFreeTextFieldWrapperRef.value);
};

const onKeypress = (e: KeyboardEvent) => {
  if (e.key == "Enter" && localValue.value.split("\n").length > 9) {
    e.preventDefault();
    return false;
  }
};

const closeTextArea = (
  textAreaElement: HTMLTextAreaElement | null,
  wrapperElem: HTMLDivElement | null,
) => {
  if (props.isExpanded || !textAreaElement || !wrapperElem) {
    return;
  }

  wrapperElem.setAttribute("style", `height: ${wrapperElem.offsetHeight}px;`);
  isTextAreaOpen.value = true;
  if (textAreaElement.scrollTop > 0) {
    textAreaElement.scrollTop = 0;
  }
  window.requestAnimationFrame(() => {
    wrapperElem.setAttribute("style", "");
  });
};

const openTextArea = () => {
  isTextAreaOpen.value = true;
};
</script>

<style lang="postcss" scoped>
textarea {
  @apply resize-none !important;
}

textarea,
.form-textarea {
  transition: height 300ms ease;
}

.is-required::after {
  @apply text-error-600;
  content: " *";
}
</style>
