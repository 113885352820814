<template>
  <a
    :href="mattermostLink"
    target="_blank"
    class="no-underline flex items-center ml-2 font-sans hover:underline"
  >
    <SvgIcon
      name="compass"
      class="w-6 h-6 fill-transparent"
      aria-hidden="true"
    />
    <div class="ml-3">
      {{ $t("general.mattermostLink") }}
    </div>
  </a>
</template>

<script lang="ts" setup>
import { computed } from "vue";
import { useAuthStore } from "@/store/Auth";
import { useRuntimeEnv } from "@core/src/helpers/environment";

const runtimeEnv = useRuntimeEnv();

const authStore = useAuthStore();

const mattermostLink = computed(() => {
  return `${runtimeEnv.VITE_MATTERMOST_URL}/${authStore.activeTenant}`;
});
</script>
