import { defineStore } from "pinia";
import { omit } from "lodash";

import type IDialog from "@/interfaces/IDialog";
import type IDossier from "@/interfaces/IDossier";
import type IDossierConsumptionData from "@/interfaces/IDossierConsumptionData";
import type IDossierProfile from "@/interfaces/IDossierProfile";
import type IErrorData from "@/interfaces/IErrorData";
import type IDossierGoalsData from "@/interfaces/IDossierGoalsData";
import type IMilestone from "@/interfaces/IMileStone";
import type IDossierQuitPlanData from "@/interfaces/IDossierQuitPlanData";
import type { IQuitPlanRiskSituation } from "@/interfaces/IQuitPlanRiskSituation";
import type { IQuitPlanWithdrawalSyndrome } from "@/interfaces/IQuitPlanWithdrawalSyndrom";
import type { IQuitPlanRelapse } from "@/interfaces/IQuitPlanRelapse";
import type IAppointment from "@/interfaces/IAppointment";
import type IDossierNote from "@/interfaces/IDossierNote";

import {
  sortRelapses,
  sortRiskSituationAndWithdrawalSyndromes,
  sortMilestones,
  sortAppointmentsByDate,
  setTimeOnDate,
} from "@/helpers/sorting";
import { hasObjectedChanged } from "@/helpers/hasObjectChanged";
import { deepClone } from "@/helpers/deepClone";

interface State {
  dialogs: IDialog[];
  dialogTopic: string | null;
  savedDialogTopic: string | null;
  dialogTopicErrors: IErrorData;
  dossier: IDossier | null;
  profile: IDossierProfile;
  savedProfile: IDossierProfile;
  quitPlanData: IDossierQuitPlanData;
  savedQuitPlanData: IDossierQuitPlanData;
  quitPlanDataErrors: IErrorData;
  profileErrors: IErrorData;
  consumptionData: IDossierConsumptionData;
  savedConsumptionData: IDossierConsumptionData;
  consumptionDataErrors: IErrorData;
  goalData: IDossierGoalsData;
  goalDataErrors: IErrorData;
  savedGoalData: IDossierGoalsData;
  appointments: IAppointment[];
  notes: IDossierNote[];
  savedNotes: IDossierNote[];
  newNoteText: string;
  notesOptions: number[];
  importantInformation: string;
  savedImportantInformation: string;
  notesErrors: IErrorData;
  dossierMode: "comments" | "info";
  isAnonymising: boolean;
  scrollToDialogId: number;
  isUserDeleted: boolean;
  canLeaveRoute: boolean;
  shouldLoadDossier: boolean;
  showLoadingSpinner: boolean;
  showLoadingError: boolean;
  activeRequests: number;
  loadingPercent: number | null;
}

const profileDefaults = {
  id: null,
  username: null,
  activatedTimestamp: null,
  loginTimestamp: null,
  gender: null,
  language: null,
  cohort: null,
  firstName: "",
  lastName: "",
  telephone: null,
  mobilePhone: null,
  counselingLanguage: null,
  cantonOfResidence: null,
  registrationLinkSentTimestamp: null,
  registrationActivationCode: null,
};

const consumptionDataDefaults = {
  id: null,
  currentStanding: "",
  products: [],
  durationYears: null,
  durationMonths: null,
  longestBreakYears: null,
  longestBreakMonths: null,
  longestBreakWeeks: null,
  longestBreakDays: null,
  timeUntilConsumption: "",
  addictionLevel: "",
  dedication: null,
  confidence: null,
};

const riskSituationDefault: IQuitPlanRiskSituation = {
  description: "",
  action: "",
  isCurrent: true,
  datetime: new Date().toISOString(),
};

const withdrawalSyndromeDefault: IQuitPlanWithdrawalSyndrome = {
  description: "",
  action: "",
  isCurrent: true,
  datetime: new Date().toISOString(),
};

const relapseDefault: IQuitPlanRelapse = {
  reason: "",
  action: "",
  datetime: new Date().toISOString(),
};

const quitPlanDefaults: IDossierQuitPlanData = {
  id: null,
  recentHelpInfo: "",
  action: "",
  riskSituations: [],
  withdrawalSyndromes: [],
  relapses: [],
};

const goalDataDefaults: IDossierGoalsData = {
  id: null,
  milestones: [],
  reason: "",
  description: "",
  option: null,
  exitDate: null,
};

const milestoneDataDefaults: IMilestone = {
  id: null,
  description: "",
  exitDate: null,
  reward: "",
  isCurrent: true,
  datetime: new Date().toISOString(),
};

export const useConversationStore = defineStore("conversation", {
  state: (): State => ({
    dialogs: [],
    dialogTopic: null,
    savedDialogTopic: null,
    dialogTopicErrors: {},
    dossier: null,
    profile: { ...profileDefaults },
    savedProfile: { ...profileDefaults },
    quitPlanData: deepClone(quitPlanDefaults),
    savedQuitPlanData: deepClone(quitPlanDefaults),
    profileErrors: {},
    consumptionData: { ...consumptionDataDefaults },
    savedConsumptionData: { ...consumptionDataDefaults },
    consumptionDataErrors: {},
    goalData: { ...goalDataDefaults },
    savedGoalData: { ...goalDataDefaults },
    goalDataErrors: {},
    quitPlanDataErrors: {},
    appointments: [],
    notes: [],
    savedNotes: [],
    newNoteText: "",
    notesOptions: [],
    importantInformation: "",
    savedImportantInformation: "",
    notesErrors: {},
    dossierMode: "info",
    isAnonymising: false,
    scrollToDialogId: 0,
    isUserDeleted: false,
    canLeaveRoute: false,
    shouldLoadDossier: true,
    showLoadingSpinner: false,
    showLoadingError: false,
    activeRequests: 0,
    loadingPercent: null,
  }),
  getters: {
    latestDialog(state) {
      return state.dialogs[state.dialogs.length - 1];
    },
    dialogId(state) {
      return (
        state.dialogs.filter((dialog) => dialog.finishedTime === null)?.[0]
          ?.id || 0
      );
    },
    sortedRiskSituations(state) {
      return [...state.quitPlanData.riskSituations].sort(
        sortRiskSituationAndWithdrawalSyndromes
      );
    },
    sortedWithdrawalSyndromes(state) {
      return [...state.quitPlanData.withdrawalSyndromes].sort(
        sortRiskSituationAndWithdrawalSyndromes
      );
    },
    sortedRelapses(state) {
      return state.quitPlanData.relapses.sort(sortRelapses);
    },
    sortedMilestones(state) {
      return sortMilestones(state.goalData.milestones);
    },
    quitPlanHasChanged(state) {
      return hasObjectedChanged(state.quitPlanData, state.savedQuitPlanData);
    },
    hasGoalDataChanged(state) {
      return hasObjectedChanged(state.goalData, state.savedGoalData);
    },
    notesHaveChanged(state) {
      return (
        state.notes.some(
          (note, i) =>
            note.status !== "deleted" &&
            note.editMode !== false &&
            hasObjectedChanged(omit(note, "editMode"), state.savedNotes[i])
        ) || state.newNoteText !== ""
      );
    },
    sortedFutureAppointments(state) {
      const now = new Date();
      return state.appointments
        .filter((item: IAppointment) => {
          const date = new Date(item.date || "");
          if (item.slot?.endTime) {
            setTimeOnDate(date, item.slot?.endTime);
          }
          return date ? date >= now : true;
        })
        .sort(sortAppointmentsByDate);
    },
    sortedPastAppointments(state) {
      const now = new Date();
      return state.appointments
        .filter((item: IAppointment) => {
          const date = new Date(item.date || "");
          if (item.slot?.endTime) {
            setTimeOnDate(date, item.slot?.endTime);
          }
          return date ? date < now : false;
        })
        .sort(sortAppointmentsByDate)
        .reverse();
    },
  },
  actions: {
    setDialogs(dialogs: IDialog[]) {
      this.dialogs = dialogs;
    },

    setDossier(dossier: IDossier) {
      this.dossier = dossier;
    },

    setProfile(profile: IDossierProfile) {
      this.profile = profile;
      this.savedProfile = { ...profile };
    },

    setProfileValue(key: string, value: any) {
      // Object.assign is used, to avoid all the typescript issues of setting
      // a dynamic key, value pair on the object with profile[key] = value.
      Object.assign(this.profile, { [key]: value });
      Object.assign(this.savedProfile, { [key]: value });
    },

    setConsumptionData(consumptionData: IDossierConsumptionData) {
      this.consumptionData = consumptionData;
      // Create deep copy of consumption data, since products contains more objects
      this.savedConsumptionData = deepClone(consumptionData);
    },

    setQuitPlanData(quitPlanData: IDossierQuitPlanData) {
      this.quitPlanData = quitPlanData;
      this.savedQuitPlanData = deepClone(quitPlanData);

      // risk and withdrawal syndromes should at least contain one item
      if (quitPlanData.riskSituations.length === 0) {
        this.addRiskSituation();
      }
      if (quitPlanData.withdrawalSyndromes.length === 0) {
        this.addWithdrawalSyndrome();
      }
    },

    setConsumptionDataValue(key: string, value: any) {
      Object.assign(this.consumptionData, { [key]: value });
      // Create deep copy of the value, in case it contains an object reference
      Object.assign(this.savedConsumptionData, {
        [key]: deepClone(value),
      });
    },

    addRiskSituation() {
      const riskSituation = {
        ...riskSituationDefault,
        datetime: new Date().toISOString(),
      };
      this.quitPlanData.riskSituations.push(riskSituation);
      this.savedQuitPlanData.riskSituations.push({ ...riskSituation });
    },

    addWithdrawalSyndrome() {
      const withdrawalSyndrome = {
        ...withdrawalSyndromeDefault,
        datetime: new Date().toISOString(),
      };
      this.quitPlanData.withdrawalSyndromes.push(withdrawalSyndrome);
      this.savedQuitPlanData.withdrawalSyndromes.push({
        ...withdrawalSyndrome,
      });
    },

    addRelapse() {
      const relapse = {
        ...relapseDefault,
        datetime: new Date().toISOString(),
      };
      this.quitPlanData.relapses.push(relapse);
      this.savedQuitPlanData.relapses.push({ ...relapse });
    },

    addGoalMilestone() {
      const milestone: IMilestone = {
        ...milestoneDataDefaults,
      };
      this.goalData.milestones.push(milestone);
    },

    setGoalData(goal: IDossierGoalsData) {
      this.goalData = goal;
      // Create deep copy of goal data, since goals contains more milestones which are objects itself
      this.savedGoalData = JSON.parse(JSON.stringify(goal));
    },

    setNotes(notes: IDossierNote[]): void {
      this.notes = notes;
      this.savedNotes = JSON.parse(JSON.stringify(notes));
    },

    addNote(note: IDossierNote): void {
      this.notes.push(note);
      this.savedNotes.push({ ...note });
    },

    updateNote(note: IDossierNote): void {
      // Review note: I don't feel like this is the best way to update the note
      const index = this.savedNotes.findIndex((n) => n.id === note.id);
      if (index !== -1) {
        this.notes.splice(index, 1, note);
        this.savedNotes.splice(index, 1, { ...note });
      }
    },

    deleteNote(noteId: number): void {
      const index = this.notes.findIndex((n) => n.id === noteId);
      this.notes[index].content = "";
      this.notes[index].status = "deleted";
    },

    resetNote(noteId: number): void {
      const origin = this.savedNotes.find(
        (note: IDossierNote) => note.id === noteId
      );
      const noteToReset = this.notes.find(
        (note: IDossierNote) => note.id === noteId
      );
      if (origin && noteToReset) {
        noteToReset.content = origin.content;
      }
    },

    setMedicalInformation(notesOptions: number[]): void {
      this.notesOptions = notesOptions;
    },

    showComments() {
      this.dossierMode = "comments";
    },

    showInfo() {
      this.dossierMode = "info";
    },

    setIsAnonymising(isAnonymising: boolean) {
      this.isAnonymising = isAnonymising;
    },

    reset() {
      this.$reset();
    },

    setTextAnonymousFromMessage({
      text,
      messageId,
    }: {
      text: string;
      messageId: number;
    }) {
      const dialogs = this.dialogs.map((dialog) => {
        dialog.messages = dialog.messages.map((message) => {
          if (message.id === messageId) {
            message.textAnonymous = text;
          }
          return message;
        });
        return dialog;
      });
      this.setDialogs(dialogs);
    },

    setTopicAnonymousFromDialog({
      topic,
      dialogId,
    }: {
      topic: string;
      dialogId: number;
    }) {
      const dialogs = this.dialogs.map((dialog) => {
        if (dialog.id === dialogId) {
          dialog.topicAnonymous = topic;
        }
        return dialog;
      });
      this.setDialogs(dialogs);
    },

    setScrollToDialogId(scrollToDialogId: number) {
      this.scrollToDialogId = scrollToDialogId;
    },

    setIsUserDeleted(isUserDeleted: boolean) {
      this.isUserDeleted = isUserDeleted;
    },

    enableRouteLeave() {
      // Allows the dossier to be left without user confirmation
      this.canLeaveRoute = true;
    },

    setShowLoadingSpinner(showLoadingSpinner: boolean) {
      this.showLoadingSpinner = showLoadingSpinner;
    },

    openRequest() {
      this.activeRequests += 1;
      // Show less progress the more requests there are
      this.loadingPercent = 75 / this.activeRequests;
    },

    closeRequest() {
      this.activeRequests -= 1;
      if (this.activeRequests === 0) {
        // It being null means the requests are complete
        this.loadingPercent = null;
      } else {
        this.loadingPercent = 75 / this.activeRequests;
      }
    },

    setAppointments(appointments: IAppointment[]) {
      this.appointments = appointments;
    },

    setDialogTopic(topic: string | null) {
      this.dialogTopic = topic;
      this.savedDialogTopic = topic;
    },
    setImportantInformation(information: string) {
      this.importantInformation = information;
      this.savedImportantInformation = information;
    },
  },
});
