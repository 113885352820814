<template>
  <div class="w-full py-12 bg-secondary h-[120px] pl-[199px]">
    <!-- route to formular as soon as implemented -->
    <div class="flex items-center">
      <a
        class="no-underline mr-16 hover:underline"
        :href="feedbackFormLink"
        target="_blank"
      >
        <div class="flex items-center">
          <SvgIcon
            name="message-error"
            class="mr-3 svg-icon--lg text-tpfYellow"
          />
          {{ $t("login.footer.problem") }}
        </div>
      </a>

      <button
        v-if="isLoggedIn"
        class="font-sansbold duration-250 hover:underline decoration-2"
        @click="showSMSOverlay = true"
      >
        <div class="flex items-center">
          <SvgIcon name="mobile-sms" class="mr-3 svg-icon--lg text-tpfYellow" />
          {{ $t("login.footer.sms.title") }}
        </div>
      </button>
    </div>

    <Modal
      :is-open="showSMSOverlay"
      class-body="sms-modal"
      @close-modal="showSMSOverlay = false"
    >
      <SMSOverlay @close="showSMSOverlay = false" />
    </Modal>
  </div>
</template>

<script setup lang="ts">
import { ref, computed } from "vue";

import Modal from "@shared/src/components/Modal.vue";
import SMSOverlay from "@/components/SMSOverlay.vue";

import { useAuthStore } from "@/store/Auth";
import { useRuntimeEnv } from "@core/src/helpers/environment";

const runtimeEnv = useRuntimeEnv();
const authStore = useAuthStore();

const showSMSOverlay = ref(false);

const isLoggedIn = computed(() => {
  return authStore.isLoggedIn;
});

const feedbackFormLink = computed(() => {
  return runtimeEnv.VITE_FEEDBACK_FORM_URL;
});
</script>

<style lang="postcss">
.sms-modal {
  width: 49rem; /* 784px */
}
</style>
