<template>
  <VDropdown @show="isShown = true" @hide="isShown = false">
    <button class="flex items-center ml-2 hover:underline decoration-2">
      <SvgIcon name="video-light" class="w-6 h-6" aria-hidden="true" />
      <div class="ml-3">
        {{ $t("general.videoConference") }}
      </div>
    </button>

    <template #popper>
      <div class="py-2">
        <!--
        The v-if part is a little trick to force the component to be
        re-rendered so each time the button is pressed, the URL will be
        genereated newly
      -->
        <VideoConferenceGenerator v-if="isShown" />
      </div>
    </template>
  </VDropdown>
</template>

<script lang="ts" setup>
import { ref } from "vue";

import VideoConferenceGenerator from "@/components/Header/VideoConferenceGenerator.vue";

const isShown = ref(false);
</script>
