import type IDossierNote from "@/interfaces/IDossierNote";
import { messagingApi } from "@/services/api";
import type IMedicalInformation from "@/interfaces/IMedicalInformation";

const getDossierNotes = async (dossierId: number): Promise<IDossierNote[]> => {
  const response = await messagingApi.get(
    `notes/notes/?dossier_id=${dossierId}`
  );
  return response.data;
};

const createDossierNote = async (
  dossierId: number,
  content: string
): Promise<IDossierNote> => {
  const response = await messagingApi.post(
    `notes/notes/?dossier_id=${dossierId}`,
    {
      dossier: dossierId,
      content,
    }
  );
  return response.data;
};

const updateDossierNote = async (
  noteId: number,
  content: string
): Promise<IDossierNote> => {
  const response = await messagingApi.patch(`notes/notes/${noteId}/`, {
    content,
  });
  return response.data;
};

const deleteDossierNote = async (noteId: number): Promise<IDossierNote> => {
  const response = await messagingApi.delete(`notes/notes/${noteId}/`);
  return response.data;
};

const getNotesOptions = async (): Promise<IMedicalInformation[]> => {
  const response = await messagingApi.get(`counseling/medical-information/`);
  return response.data;
};

const getMedicalInformation = async (dialogId: number): Promise<number[]> => {
  const response = await messagingApi.get(
    `counseling/dialog/${dialogId}/get_medical_information/`
  );
  return response.data;
};

const updateMedicalInformation = async (
  dialogId: number,
  optionIds: number[]
) => {
  const response = await messagingApi.post(
    `counseling/dialog/${dialogId}/set_medical_information/`,
    {
      ids: optionIds,
    }
  );
  return response.data;
};

const updateDossierImportantInformation = async (
  dossierId: number,
  information: string
) => {
  const response = await messagingApi.patch(
    `counseling/dossier/${dossierId}/`,
    {
      importantInformation: information,
    }
  );
  return response.data;
};

export {
  getDossierNotes,
  createDossierNote,
  updateDossierNote,
  deleteDossierNote,
  getNotesOptions,
  getMedicalInformation,
  updateMedicalInformation,
  updateDossierImportantInformation,
};
