import type IDialog from "@/interfaces/IDialog";
import { messagingApi } from "./api";
import type IDossier from "@/interfaces/IDossier";
import type IDossierProfile from "@/interfaces/IDossierProfile";
import type IDossierConsumptionData from "@/interfaces/IDossierConsumptionData";
import type IDossierQuitPlanData from "@/interfaces/IDossierQuitPlanData";
import { parseQuitPlanDataPayload } from "@/services/quitPlanData";
import type IDossierGoalsData from "@/interfaces/IDossierGoalsData";
import { parseGoalDataPayload } from "@/services/goalTab";
import { parseProfilePayload } from "@/services/profile";

const sendMessage = async (
  text: string,
  dialogId: number,
  isDraft: boolean
) => {
  const data = {
    text,
    dialogId,
    isDraft,
  };
  const response = await messagingApi.post("counseling/message/", data);
  return response.data;
};

const sendInitialMessage = async (text: string, dossierId: number) => {
  const data = {
    text,
    dossierId,
  };
  const response = await messagingApi.post("counseling/message/", data);
  return response.data;
};

const getConversation = async (dialogId: number) => {
  const response = await messagingApi.get(
    `counseling/conversation/${dialogId}/`
  );

  return response.data;
};

const getUnreadDialogCount = async () => {
  const response = await messagingApi.get("counseling/dialog/unread/");
  return response.data.count;
};

const getDialogs = async (
  filter: string,
  showFinished: boolean,
  page: number
) => {
  const response = await messagingApi.get(
    `counseling/dialog/?${filter}&show_finished=${showFinished}&page=${page}`
  );
  return response.data;
};

const getDialogsFilter = async (filter: string, showFinished: boolean) => {
  const response = await messagingApi.options(
    `counseling/dialog/?${filter}&show_finished=${showFinished}`
  );
  return response.data;
};

const updateDraft = async (id: number, text: string) => {
  const data = {
    text,
  };
  const response = await messagingApi.put(`counseling/draft/${id}/`, data);

  return response.data;
};

const deleteDraft = async (id: number) => {
  const response = await messagingApi.delete(`counseling/draft/${id}/`);

  return response.data;
};

const getDossier = async (id: number) => {
  const response = await messagingApi.get(`counseling/dossier/${id}/`);
  return response.data;
};

const getDossierAnonymous = async (id: number): Promise<IDossier> => {
  const response = await messagingApi.get(
    `counseling/dossier/${id}/?anonymous`
  );

  return response.data;
};

const sendDraft = async (id: number) => {
  const response = await messagingApi.post(`counseling/draft/${id}/send/`);

  return response.data;
};

const getAllExperts = async () => {
  // TODO: Implement tenant
  const response = await messagingApi.get(`/account/expert/`);
  return response.data;
};

const setDossierExpert = async (dossierId: number, expertId: number | null) => {
  await messagingApi.patch(`/counseling/dossier/${dossierId}/`, {
    expert: expertId,
  });
};

const rejectDossier = async (dossierId: number) => {
  await messagingApi.patch(`/counseling/dossier/${dossierId}/`, {
    expert: null,
    isAssignmentRejected: true,
  });
};

const setDossierFinishedState = async (
  dossierId: number,
  dossierState: boolean
) => {
  await messagingApi.patch(`/counseling/dossier/${dossierId}/`, {
    isFinished: dossierState,
  });
};

const uploadDocument = async (formData: FormData) => {
  const response = await messagingApi.post(`counseling/attachment/`, formData, {
    headers: { "Content-Type": "multipart/form-data" },
  });
  return response.data;
};

const deleteAttachment = async (attachmentId: number) => {
  const response = await messagingApi.delete(
    `counseling/attachment/${attachmentId}/`
  );
  return response.data;
};

const downloadAttachment = async (link: string) => {
  const response = await messagingApi.get(link, { responseType: "blob" });
  return response.data;
};

const deleteComment = async (id: number) => {
  const response = await messagingApi.delete(`counseling/comment/${id}/`);
  return response.data;
};

const saveComment = async (id: number, text: string) => {
  const response = await messagingApi.put(`counseling/comment/${id}/`, {
    text,
  });
  return response.data;
};

const addComment = async (messageId: number, text: string) => {
  const response = await messagingApi.post(`counseling/comment/`, {
    text,
    message: messageId,
  });
  return response.data;
};

const updateDialog = async (dialog: IDialog) => {
  const response = await messagingApi.put(
    `counseling/dialog/${dialog.id}/`,
    dialog
  );
  return response.data;
};

const updateDialogTopic = async (
  id: number | undefined,
  topic: string | null
) => {
  const response = await messagingApi.patch(`counseling/dialog/${id}/`, {
    topic: topic,
  });
  return response.data;
};

const patchDialog = async (dialog: any) => {
  const response = await messagingApi.patch(
    `counseling/dialog/${dialog.id}/`,
    dialog
  );

  return response.data;
};

const setDossierLanguage = async (dossierId: number, languageId: number) => {
  await messagingApi.patch(`/counseling/dossier/${dossierId}/`, {
    language: languageId,
  });
};

const getPublicDialogs = async (
  filter: string,
  showFinished: boolean,
  page: number
) => {
  const response = await messagingApi.get(
    `counseling/public-dossier/?${filter}&show_finished=${showFinished}&page=${page}`
  );
  return response.data;
};

const getPublicDialogsFilter = async (
  filter: string,
  showFinished: boolean
) => {
  const response = await messagingApi.options(
    `counseling/public-dossier/?${filter}&show_finished=${showFinished}`
  );
  return response.data;
};

const createDossier = async (
  client: IDossierProfile,
  consumptionData: IDossierConsumptionData,
  quitPlanData: IDossierQuitPlanData,
  goalData: IDossierGoalsData,
  dialogTopic: string | null
): Promise<{
  dossier: IDossier;
  client: IDossierProfile;
  consumptionData: IDossierConsumptionData;
  quitPlan: IDossierQuitPlanData;
  goals: IDossierGoalsData;
  dialogTopic: string | null;
  dialogs: IDialog[];
}> => {
  const response = await messagingApi.post(`/counseling/dossier/`, {
    client: parseProfilePayload(client),
    consumptionData: consumptionData,
    quitPlan: parseQuitPlanDataPayload(quitPlanData),
    goals: parseGoalDataPayload(goalData),
    dialogTopic,
  });
  return response.data;
};

const archiveDossier = async (id: number, data: object) => {
  const response = await messagingApi.post(
    `/counseling/dossier/${id}/archive/`,
    data
  );
  return response.data;
};

const unArchiveDossier = async (id: number) => {
  const response = await messagingApi.post(
    `/counseling/dossier/${id}/unarchive/`,
    {}
  );
  return response.data;
};

const getCategories = async () => {
  const response = await messagingApi.get(`counseling/category/`);
  return response.data;
};

const loadCalls = async (dialogId: number) => {
  const response = await messagingApi.get(
    `counseling/dialog/${dialogId}/calls/`
  );
  return response.data;
};

const createCall = async (dialogId: number, data: object) => {
  const response = await messagingApi.post(
    `counseling/dialog/${dialogId}/call/`,
    data
  );
  return response.data;
};

const updateDossierState = async (dossierId: number, state: string) => {
  await messagingApi.patch(`/counseling/dossier/${dossierId}/`, {
    state: state,
  });
};

export {
  sendMessage,
  sendInitialMessage,
  sendDraft,
  getConversation,
  updateDraft,
  deleteDraft,
  getDialogs,
  getDossier,
  getAllExperts,
  setDossierExpert,
  setDossierFinishedState,
  uploadDocument,
  deleteAttachment,
  downloadAttachment,
  deleteComment,
  saveComment,
  addComment,
  getDossierAnonymous,
  updateDialog,
  updateDialogTopic,
  setDossierLanguage,
  getPublicDialogs,
  patchDialog,
  rejectDossier,
  getDialogsFilter,
  getPublicDialogsFilter,
  createDossier,
  archiveDossier,
  unArchiveDossier,
  getUnreadDialogCount,
  loadCalls,
  createCall,
  getCategories,
  updateDossierState,
};
